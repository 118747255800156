import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/NavigationSystem.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Improved Dispatch Decisions ",
    paragraph:
      "Make smarter dispatch decisions using real-time tracking as a guide. ",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Travel Routes",
    paragraph: "Identify the best travel routes to increase efficiency.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Vehicle Security",
    paragraph: "Maintain vehicle security with GPS tracking.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Increased Customer Confidence",
    paragraph: "Build customer confidence with accurate location information.",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Employee Accountability IP-Line",
    paragraph: "Ensure staff accountability by monitoring driving behaviour. ",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Save Time and Money",
    paragraph: "Cut costs and save time with shorter routes.  ",
  },
];

export const HeaderData = {
  src: Internet,
  title: "Navigation System",
  subtitle: "Never Get Lost Again",
  paragraph: "Use advanced tracking to increase business productivity",
  to: Product_Quote_Routes.NavigationSystem,
};

export const DescriptionContent = {
  title: "Why Do You Need a Navigation System?",
  paragraph:
    "An effective navigation system makes your business more productive by eliminating the need to ask for directions. It also provides control over units from a centralised location and allows you to plan routing in advance.",
  to: Product_Quote_Routes.NavigationSystem,
  profitPoint1: "Improved control over routes and units",
  profitPoint2: "Better capacity to plan",
  profitPoint3: "Increased business efficiency",
  profitPoint4: "More accurate information to give to customers",
};

export const FAQS = [
  {
    title: "What kind of business needs a navigation system?",
    answer:
      "Any business that involves vehicle travel could benefit from a navigation system.",
  },
  {
    title: "What sort of features do good navigation systems offer?",
    answer:
      "Some of the features to look out for include driver safety tracking, integration with fuel cards, mobile access, and alert systems.    ",
  },
  {
    title: "Are all navigation systems the same?",
    answer:
      "No, different navigation systems offer different features to suit different needs.  ",
  },
  {
    title: "How much do navigation systems cost?",
    answer:
      "Navigation systems come at various price points. Contact Digital Comparison and we’ll set you up with the best quotes from trusted industry professionals according to your needs.   ",
  },
  {
    title: "What are the benefits of a navigation system?",
    answer:
      "An effective navigation system can help your business to increase efficiency by choosing smarter routes. It can also increase vehicle security and give you more control over employees. ",
  },
  {
    title: "How does a GPS know my location?",
    answer:
      "A GPS connects with several GPS satellites through either built-in or external antenna to locate your position.",
  },
  {
    title: "How can Digital Comparison get me a good navigation system?",
    answer:
      "We work with several leading navigation system suppliers. Fill out our questionnaire and we’ll organise free quotes from the providers that most suit your requirements. ",
  },
  {
    title: "Which navigation system provider is the best?",
    answer:
      "We only partner with qualified and trusted navigation system partners. The best one for you depends on your individual needs and those of your business.     ",
  },
];
