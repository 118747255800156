import * as React from "react";
import {
  CallToAction,
  Layout,
  MainBackground,
  ProductBenifits,
  ProductDescription,
  ProductHeader,
  FAQ,
} from "../../components/containers";
import SEO from "../../components/marketing/Seo";
import Accordion from "../../components/utility/accordian/Accordion";
import { Card } from "../../components/utility/Card";
import { Navigation_Meta } from "../../data/MetaData";

import {
  DescriptionContent,
  HeaderData,
  Keybenifits,
  FAQS,
} from "../../data/product-page-data/NavigationData";

const NavigationSystem = () => {
  return (
    <Layout>
      <SEO
        title={Navigation_Meta.title}
        description={Navigation_Meta.description}
      />
      <ProductHeader
        src={HeaderData.src}
        subTitle={HeaderData.subtitle}
        title={HeaderData.title}
        paragraph={HeaderData.paragraph}
        to={HeaderData.to}
      />

      <MainBackground>
        <ProductDescription
          title={DescriptionContent.title}
          paragraph={DescriptionContent.paragraph}
          to={DescriptionContent.to}
          profitPoint1={DescriptionContent.profitPoint1}
          profitPoint2={DescriptionContent.profitPoint2}
          profitPoint3={DescriptionContent.profitPoint3}
          profitPoint4={DescriptionContent.profitPoint4}
        />
        <CallToAction />
        <ProductBenifits
          benifit={Keybenifits.map((item, id) => {
            return (
              <Card
                key={id}
                icon={<item.src />}
                title={item.title}
                paragraph={item.paragraph}
              />
            );
          })}
        />
        <FAQ>
          {FAQS.map((item) => {
            return <Accordion title={item.title} content={item.answer} />;
          })}
        </FAQ>
      </MainBackground>
    </Layout>
  );
};

export default NavigationSystem;
